.react-calendar {
  width: 300px;
  max-width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  font-family: 'Helvetica', Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__navigation {
  margin-bottom: 18px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.react-calendar__navigation button {
  border: 0;
  background: inherit;
  color: #7f7f7f;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation button {
  min-width: 44px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  cursor: pointer;
}
.react-calendar__navigation button[disabled] {
  background: inherit;
  color: #a6a6a6;
}
abbr[title] {
  text-decoration: none;
}
.react-calendar__tile {
  height: 38px;
  max-width: 38px;
  background: inherit;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 9.5px 15px !important;
}

.react-calendar__tile:hover .leftSide-item:last-of-type {
  background: rgba(0, 106, 228, 0.1);
  border-radius: 6px;
  color: #006ae4;
}

.react-calendar__month-view__weekdays {
  display: flex;
  margin-bottom: 23px;
}

.react-calendar__month-view__weekdays div {
  color: #7f7f7f;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

.react-calendar__month-view__days {
  row-gap: 5px;
  column-gap: 5px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  color: #454545;
  color: #006ae4;
  border-radius: 6px;
}

.react-calendar__tile--active {
  background: #006ae4;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #a6a6a6 !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #006ae4;
  color: white;
  border-radius: 6px;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background: rgba(0, 106, 228, 0.1);
  border-radius: 6px;
}

.react-calendar__tile--range {
  background: rgba(0, 106, 228, 0.1);
  color: #454545;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: #006ae4 !important;
  border-radius: 6px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #ffffff !important;
}
.react-calendar__navigation__arrow {
  font-size: 25px !important;
}
